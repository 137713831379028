import axios from "axios";

export const submitImage = async (hairId, fileForImg, keyName) => {
  const addImage = new FormData();
  addImage.append("image", fileForImg);
  addImage.append("hairId", hairId);
  addImage.append("keyName", keyName);
  await axios.post(
    `${process.env.REACT_APP_BACKEND_SERVER_URL}/api/hair/addImagesToTicket`,
    addImage,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};
