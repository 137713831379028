import CreateHair from "../../components/CreateHair/CreateHair";
import styles from "./Landing.module.css";
import Login from "../Login/Login";

const Landing = ({ user, handleSignupOrLogin }) => {
  return (
    <main className={styles.container}>
      <div>
        {user ? (
          <CreateHair />
        ) : (
          <Login handleSignupOrLogin={handleSignupOrLogin} />
        )}
      </div>
      {/* <CreateHair /> */}
    </main>
  );
};

export default Landing;
