import * as tokenService from "../services/tokenService";

const createGalleryApi = async (data) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_SERVER_URL}/api/gallery/createGallery`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${tokenService.getToken()}`,
      },
      body: JSON.stringify(data),
    }
  );
  return await res.json();
};

const getAllGallery = async () => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_SERVER_URL}/api/gallery/getAllGallery`,
    {
      headers: { Authorization: `Bearer ${tokenService.getToken()}` },
    }
  );
  let data = await res.json();
  return data;
};

const deleteGallery = async (galId) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_SERVER_URL}/api/gallery/deleteGal/${galId}`,
    {
      method: "POST",

      headers: { Authorization: `Bearer ${tokenService.getToken()}` },
    }
  );
  let data = await res.json();
  return data;
};
export { createGalleryApi, getAllGallery, deleteGallery };
