import { Button, IconButton } from "@mui/material";
import AddImages from "../AddImages/AddImages";
import styles from "./Products.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
function PreviewProducts({
  inputValue,
  setFileForImg,
  fileForImg,
  previewImage,
  setPreviewImage,
  createLength,
  setCreateLength,
}) {
  const deleteLength = (idx) => {
    const newLength = [...createLength];
    newLength.splice(idx, 1);
    setCreateLength(newLength);
  };
  return (
    <div>
      <div className={styles.card}>
        <div className={styles.imageContainer}>
          <AddImages
            fileForImg={fileForImg}
            setFileForImg={setFileForImg}
            previewImage={previewImage}
            testStyle={styles.hairImage}
            setPreviewImage={setPreviewImage}
          />
        </div>
        <div className={styles.productsName}>
          <h1>{inputValue?.name}</h1>
          <h3>Read more</h3>
        </div>
        <div className={styles.productLine}></div>
        <div>
          <h1>{inputValue?.price}$</h1>
        </div>
        <div>
          <Button variant="contained" className={styles.button5}>
            Book now
          </Button>
        </div>
      </div>
      <div>
        <div className={styles.createLengthCon}>
          {createLength?.map((len, idx) => (
            <div key={idx} className={styles.createLength}>
              <p>{len.size}</p>
              <p>
                {len.time1} to {len.time2} hours
              </p>
              <p> {len.price}$</p>
              <IconButton aria-label="delete" onClick={deleteLength}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PreviewProducts;
