import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import "./AddImages.css";

const AddImages = ({
  setFileForImg,
  fileForImg,
  previewImage,
  setPreviewImage,
  testStyle,
}) => {
  const [imageKey, setImageKey] = useState(0); // Add state to hold the key

  const fileInputRef = useRef();

  useEffect(() => {
    if (fileForImg) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setPreviewImage(fileReader.result);
      };
      fileReader.readAsDataURL(fileForImg);
    } else {
      setPreviewImage(null);
    }
  }, [fileForImg, setPreviewImage]);

  const removeImage = () => {
    setPreviewImage(null);
    setFileForImg(null);
    setImageKey((prevKey) => prevKey + 1); // Increment the key to force re-render
  };

  return (
    <>
      {previewImage ? (
        <div className="AddImagesAndRemove ">
          <img
            key={imageKey} // Add a unique key to the img element
            className={testStyle}
            src={previewImage}
            alt="preview img"
          />

          <IconButton
            color="white"
            aria-label="Call Sage"
            fontSize="35px"
            onClick={removeImage}
          >
            <HighlightOffIcon />
          </IconButton>
        </div>
      ) : (
        <>
          <IconButton
            color="white"
            aria-label="Call Sage"
            fontSize="35px"
            onClick={(e) => {
              e.preventDefault();
              fileInputRef.current.click();
            }}
          >
            <AddIcon />
          </IconButton>
        </>
      )}
      <input
        ref={fileInputRef}
        onChange={(e) => setFileForImg(e.target.files[0])}
        style={{ display: "none" }}
        type="file"
        accept="image/*"
      />
    </>
  );
};

export default AddImages;
