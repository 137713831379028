import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
function SingleSelect({ lengthInputValueChange, lengthInputValue }) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Hair length</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={lengthInputValue.size}
          label="Hair length"
          name="size"
          onChange={lengthInputValueChange}
        >
          <MenuItem value={"Mid Back"}>Mid Back</MenuItem>
          <MenuItem value={"Waist Length"}>Waist Length</MenuItem>
          <MenuItem value={"Butt Length"}>Butt Length</MenuItem>
          <MenuItem value={"Knee Length"}>Knee Length</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default SingleSelect;
