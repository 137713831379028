import React, { useEffect, useState } from "react";
import { deleteGallery, getAllGallery } from "../../services/galleryServices";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function GalleryPage(props) {
  const navigate = useNavigate();
  const [getAllGal, setGetAllGal] = useState([]);
  useEffect(() => {
    const gallData = async () => {
      const data = await getAllGallery();
      setGetAllGal(data);
    };
    gallData();
  }, []);

  const deleteImage = async (galleryId) => {
    await deleteGallery(galleryId);
    const newHair = getAllGal.filter((gall) => gall._id !== galleryId);
    setGetAllGal(newHair);
  };
  return (
    <ImageList variant="masonry" cols={3} gap={8}>
      {getAllGal.map((item) => (
        <ImageListItem key={item._id}>
          <img
            src={`${item.galleryImage}?w=248&fit=crop&auto=format`}
            srcSet={`${item.galleryImage}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.galleryName}
            loading="lazy"
          />
          <IconButton
            color="white"
            aria-label="Call Sage"
            fontSize="35px"
            onClick={(e) => {
              deleteImage(item._id);
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </ImageListItem>
      ))}
    </ImageList>
  );
}

export default GalleryPage;
