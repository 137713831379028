import React from "react";
import Text from "./Text";
import SingleSelect from "./SingleSelect";
import { Button, TextField } from "@mui/material";
import "./CreateLength.css";
function CreateLength({
  lengthInputValueChange,
  lengthInputValue,
  addLengthHandler,
  disableState,
}) {
  return (
    <div className="CreateLength">
      <div className="CreateLengthCon">
        <SingleSelect
          lengthInputValueChange={lengthInputValueChange}
          lengthInputValue={lengthInputValue}
        />
        <TextField
          id="outlined-number"
          label="price"
          type="number"
          name="price"
          onChange={lengthInputValueChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="outlined-number"
          label="time1"
          type="number"
          name="time1"
          onChange={lengthInputValueChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="outlined-number"
          label="time2"
          type="number"
          name="time2"
          onChange={lengthInputValueChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className="CreateLengthButtonCon">
        {disableState ? (
          <Button variant="outlined" disabled>
            Add
          </Button>
        ) : (
          <Button variant="contained" onClick={addLengthHandler}>
            Add
          </Button>
        )}
      </div>
    </div>
  );
}

export default CreateLength;
