import { useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import Landing from "./pages/Landing/Landing";
import Profiles from "./pages/Profiles/Profiles";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import * as authService from "./services/authService";
import AllHair from "./pages/AllHair/AllHair";
import GalleryPage from "./pages/Gallery/Gallery";
import CreateGallery from "./pages/CreateGallery/CreateGallery";

const App = () => {
  const [user, setUser] = useState(authService.getUser());
  const navigate = useNavigate();

  const handleLogout = () => {
    authService.logout();
    setUser(null);
    navigate("/");
  };

  const handleSignupOrLogin = () => {
    setUser(authService.getUser());
  };

  return (
    <>
      <NavBar user={user} handleLogout={handleLogout} />
      <Routes>
        <Route
          path="/"
          element={
            <Landing user={user} handleSignupOrLogin={handleSignupOrLogin} />
          }
        />
        {/* <Route
          path="/signup"
          element={<Signup handleSignupOrLogin={handleSignupOrLogin} />}
        /> */}
        {/* <Route
          path="/login"
          element={<Login handleSignupOrLogin={handleSignupOrLogin} />}
        /> */}
        <Route
          path="/profiles"
          element={user ? <Profiles /> : <Navigate to="/login" />}
        />
        <Route
          path="/allhair"
          element={user ? <AllHair /> : <Navigate to="/" />}
        />
        <Route
          path="/gallery"
          element={user ? <GalleryPage /> : <Navigate to="/" />}
        />
        <Route
          path="/createGalleryImage"
          element={user ? <CreateGallery /> : <Navigate to="/" />}
        />
        {/* <Route
          path="/changePassword"
          element={
            user ? (
              <ChangePassword handleSignupOrLogin={handleSignupOrLogin} />
            ) : (
              <Navigate to="/login" />
            )
          }
        /> */}
      </Routes>
    </>
  );
};

export default App;
