import React, { useEffect } from "react";
import { deleteOneHair, getAllHair } from "../../services/hairService";
import styles from "./AllHair.module.css";
import { Button, IconButton, Snackbar } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const AllHair = (props) => {
  const [allHair, setAllHair] = React.useState([]);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  useEffect(() => {
    const fetchData = async () => {
      let data = await getAllHair();
      setAllHair(data);
    };
    fetchData();
  }, []);
  const deleteHair = async (hairId) => {
    await deleteOneHair(hairId);
    const newHair = allHair.filter((hair) => hair._id !== hairId);
    setAllHair(newHair);
  };

  return (
    <div className={styles.AllHair}>
      {allHair.map((hair) => (
        <div className={styles.card} key={hair?._id}>
          <div className={styles.imageContainer}>
            <img src={hair.img} alt="" className={styles.hairImage} />
          </div>
          <div className={styles.productsName}>
            <h1>{hair.name}</h1>
            <h3>Read more</h3>
          </div>
          <div className={styles.productLine}></div>
          <div>
            <h1>{hair.price}$</h1>
          </div>
          <div>
            <Button variant="contained" className={styles.button5}>
              Book now
            </Button>
          </div>
          <IconButton
            color="white"
            aria-label="Call Sage"
            fontSize="35px"
            onClick={() => deleteHair(hair._id)}
          >
            <HighlightOffIcon />
          </IconButton>
          <div className={styles.innerCardParent}>
            {hair.length.map((len) => (
              <div className={styles.innerCard} key={len?._id}>
                <div className={styles.innerProductsName}>
                  <h1>{len?.size}</h1>
                </div>
                <div>
                  <h1>{len.price}$</h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllHair;
