import * as tokenService from "../services/tokenService";

const BASE_URL = `${process.env.REACT_APP_BACKEND_SERVER_URL}/api/hair`;

const getAllHair = async () => {
  const res = await fetch(`${BASE_URL}/getAllHair`, {
    headers: { Authorization: `Bearer ${tokenService.getToken()}` },
  });
  let data = await res.json();
  return data;
};

const createHair = async (data) => {
  const res = await fetch(`${BASE_URL}/createHair`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${tokenService.getToken()}`,
    },
    body: JSON.stringify(data),
  });
  return await res.json();
};

const deleteOneHair = async (hairId) => {
  const res = await fetch(`${BASE_URL}/deleteHair/${hairId}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${tokenService.getToken()}`,
    },
  });
  return await res.json({ message: "Hair deleted" });
};
export { getAllHair, createHair, deleteOneHair };
