import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import "./CreateHair.css";
import { Button, Input } from "@mui/material";
import Text from "./Text";
import MultiSelect from "./MultiSelect";
import CreateLength from "./CreateLength";
import PreviewProducts from "./PreviewProducts";
import AllHair from "../../pages/AllHair/AllHair";
import { createHair } from "../../services/hairService";
import { submitImage } from "./HelperFunction";
function CreateHair(props) {
  const [createProduct, setCreateProduct] = useState({});
  const [createLength, setCreateLength] = useState([]);
  const [selectSection, setSelectSection] = useState([]);
  const [fileForImg, setFileForImg] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [loadingState, setLoadingState] = useState(false);
  const [disableState, setDisableState] = useState(false);
  const [inputValue, setInputValue] = useState({
    tags: [],
    name: "",
    img: "",
    price: "",
    time: "",
    bringHair: false,
    downPayment: "",
    description: "",
    length: [],
  });

  const [lengthInputValue, setLengthInputValue] = useState({
    size: "",
    time1: "",
    time2: "",
    price: "",
  });

  const inputChange = (e) => {
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [e.target.name]: e.target.value,
    }));
  };

  const createProductHandler = async () => {
    try {
      setLoadingState(true);
      setDisableState(true);
      setCreateProduct({
        ...inputValue,
        id: Math.random(),
        tags: [...selectSection],
        length: [...createLength],
      });
      const createdHairId = await createHair({
        ...inputValue,
        tags: [...selectSection],
        length: [...createLength],
      });
      if (fileForImg) {
        await submitImage(createdHairId._id, fileForImg, "hairImage");
      }
      // console.log(createdHairId);
      setDisableState(false);
      setLoadingState(false);
    } catch (err) {
      console.log(err);
    }
  };

  const lengthInputValueChange = (e) => {
    setLengthInputValue((prevInputValue) => ({
      ...prevInputValue,
      [e.target.name]: e.target.value,
    }));
  };

  const addLengthHandler = () => {
    setCreateLength([...createLength, lengthInputValue]);
  };

  useEffect(() => {
    if (createLength.length === 4) {
      setDisableState(true);
    } else {
      setDisableState(false);
    }
  }, [createLength]);

  return (
    <div className="CreateHair">
      <div className="CreateHairInput">
        <div className="CreateHairInputAll">
          <MultiSelect
            label={"tags"}
            setSelectSection={setSelectSection}
            selectSection={selectSection}
            inputChange={inputChange}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
          <Text
            label={"name"}
            inputChange={inputChange}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
          <Text
            label={"price"}
            type="number"
            id="outlined-number"
            inputChange={inputChange}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
          <Text
            label={"downPayment"}
            type="number"
            id="outlined-number"
            inputChange={inputChange}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
          <Text
            label={"time"}
            type="number"
            id="outlined-number"
            inputChange={inputChange}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
          <Text
            label={"description"}
            inputChange={inputChange}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        </div>
        <div>
          <CreateLength
            lengthInputValueChange={lengthInputValueChange}
            lengthInputValue={lengthInputValue}
            addLengthHandler={addLengthHandler}
            disableState={disableState}
          />
        </div>
      </div>
      <div>
        <PreviewProducts
          inputValue={inputValue}
          fileForImg={fileForImg}
          setFileForImg={setFileForImg}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}
          createLength={createLength}
          setCreateLength={setCreateLength}
        />
      </div>
      {loadingState ? (
        <LoadingButton loading variant="outlined">
          Submit
        </LoadingButton>
      ) : (
        <Button variant="contained" onClick={createProductHandler}>
          add Product
        </Button>
      )}
    </div>
  );
}
export default CreateHair;
