import { Link } from "react-router-dom";

const NavBar = ({ user, handleLogout }) => {
  return (
    <>
      {user ? (
        <nav>
          <ul>
            <li>Welcome, {user.name}</li>
            {/* <li><Link to="/profiles">Profiles</Link></li> */}
            <li>
              <Link to="" onClick={handleLogout}>
                LOG OUT
              </Link>
              ----------
              <Link to="/allhair">All Hair</Link>
              ----------
              <Link to="/">Create Hair</Link>
              ----------
              <Link to="/gallery">Gallery</Link>
              ----------
              <Link to="/createGalleryImage"> created Gallery</Link>
            </li>
            {/* <li><Link to="/changePassword">Change Password</Link></li> */}
          </ul>
        </nav>
      ) : (
        <nav>
          <ul>
            <li>
              <Link to="/login">Log In</Link>
            </li>
            {/* <li><Link to="/signup">Sign Up</Link></li> */}
          </ul>
        </nav>
      )}
    </>
  );
};

export default NavBar;
