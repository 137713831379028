import React, { useState } from "react";
import AddImages from "../../components/AddImages/AddImages";
import styles from "./CreateGallery.module.css";
import Text from "../../components/CreateHair/Text";
import { Button, TextField } from "@mui/material";
import { submitImage } from "../../components/CreateHair/HelperFunction";
import { createGalleryApi } from "../../services/galleryServices";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
function CreateGallery(props) {
  const [fileForImg, setFileForImg] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const inputChange = (e) => {
    e.preventDefault();
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();

  const handleAddToGallery = async () => {
    setDisableButton(true);
    const gallery = {
      ...inputValue,
    };
    const galleryId = await createGalleryApi(gallery);

    if (galleryId) {
      await submitImage(galleryId._id, fileForImg, "galleryImage");
    }
    setDisableButton(false);
    navigate("/");
  };

  return (
    <div className={styles.CreateGallery}>
      <div>
        <AddImages
          fileForImg={fileForImg}
          setFileForImg={setFileForImg}
          previewImage={previewImage}
          testStyle={styles.hairImage}
          setPreviewImage={setPreviewImage}
        />
      </div>

      <div>
        <TextField
          label={"Image Name"}
          onChange={inputChange}
          name="galleryName"
        />
        <TextField
          label={"image description"}
          name="galleryDescription"
          onChange={inputChange}
        />
      </div>
      {disableButton ? (
        <LoadingButton loading variant="outlined">
          loading....
        </LoadingButton>
      ) : (
        <Button variant="contained" onClick={handleAddToGallery}>
          Add To gallery
        </Button>
      )}
    </div>
  );
}

export default CreateGallery;
