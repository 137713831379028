import { TextField } from "@mui/material";
import React from "react";

function Text({
  label,
  inputValue,
  setInputValue,
  inputChange,
  type = "text",
  id = "outlined-basic",
}) {
  return (
    <TextField
      id={id}
      label={label}
      name={label}
      value={inputValue[label]}
      variant="outlined"
      type={type}
      onChange={inputChange}
    />
  );
}

export default Text;
